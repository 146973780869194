import cookieconsent from './cookieconsent.min.js';
import '../../css/cookieconsent.min.css';

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return false;
	}
}

export default () => {
	if (inIframe()) {
		document.cookie = 'cookieconsent_status=deny';
		// setTimeout(() => {
		window.addEventListener('load', function () {
			console.log('inIframe', inIframe());
			// $('#cookieconsent-overlay').css('display', 'none');
			document.getElementById('cookieconsent-overlay').style.display = 'none';
		});
		return;
	}
	cookieconsent();

	let url = window.location.href;
	const cookie = document.cookie.split('; ').find((row) => row.startsWith('cookieconsent_status='));
	// console.log(cookie);

	if (cookie === undefined) {
		initialiseCookieconsent();
		//get cookie buttons
		document.getElementsByClassName('cc-allow')[0].addEventListener('click', reloadFunction);
		document.getElementsByClassName('cc-deny')[0].addEventListener('click', reloadFunction);
	} else if (url.includes('datenschutz')) {
		// initialiseCookieconsent();
		//get cookie buttons
		// document.getElementsByClassName('cc-allow')[0].addEventListener('click', reloadFunction);
		// document.getElementsByClassName('cc-deny')[0].addEventListener('click', reloadFunction);
	}

	//display "overlay" on all but "index.html" and if cookie not set
	if (!url.includes('index') && cookie === undefined) {
		$('#cookieconsent-overlay').css('display', 'inline');
	}
	// if (url.includes('datenschutz') || (url.includes('impressum') && cookie === undefined)) {
	// 	$('#cookieconsent-overlay').css('display', 'none');
	// }

	//get cookie buttons
	// document.getElementsByClassName("cc-allow")[0].addEventListener("click", realoadFunction);
	// document.getElementsByClassName("cc-deny")[0].addEventListener("click", reloadAndDeny);

	//reload
	function reloadFunction() {
		window.location.reload();
	}
	//reload & count deny
	async function reloadAndDeny() {
		window.location.reload();
	}

	//Initialise Cookieconsent
	function initialiseCookieconsent() {
		window.cookieconsent.initialise({
			palette: {
				popup: {
					background: '#1d1d1d',
					text: '#ffffff',
				},
				button: {
					background: '#ffffff',
					text: '#1d1d1d',
				},
			},
			theme: 'classic',
			position: 'bottom-left',
			type: 'opt-in',
			content: {
				message:
					'Wir nutzen Cookies und Google Analytics, um diese Website für Sie so interessant wie möglich zu gestalten.',
				policy: 'Datenschutz Einstellungen',
				dismiss: 'Ablehnen',
				allow: 'Akzeptieren',
				deny: 'Ablehnen',
				link: 'Datenschutz',
				href: 'https://yobo.life/datenschutz.html',
			},
		});
	}
};
