// import NR from '@newrelic/browser-agent'
import './jq-global.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'swiper';
// import './initFullpage.js';

//!-------------------------------------------
import initialiseCookieconsent from './cookieconsent/initialiseCookieconsent.js';
import googleAnalytics from './googleAnalytics.js';
import menu from './menu.js';
import tiltJQ from './tilt-jq.js';

window.addEventListener('load', function () {
	// console.log("window loaded");
	$('#page-loader').addClass('p-hidden');
	// $(".section").addClass("anim");
	//display overly after load
	$('#cookieconsent-overlay').css('display', 'inline');
});

//* disable tab key
document.onkeydown = function (e) {
	// if (e.keyCode == 9) { ! do not use keyCode or wich
	if (e.key == 'Tab') {
		return false;
	}
};

initialiseCookieconsent();
googleAnalytics();
menu();
tiltJQ();

//* enable popper
// data-toggle="tooltip" data-placement="top" title="Tooltip on top"
$(function () {
	$('[data-toggle="tooltip"]').tooltip();
});

//? old tooltip setting - tooltip in scrollable container
// $(function () {
// 	$('[data-toggle="tooltip"]').tooltip({
// 		html: true,
// 		boundary: "viewport",
// 		// container: 'body',

// 		// trigger: 'click'
// 	});
// });
//! -------------------------------------------------------------------------

//? newrelic settings
window.addEventListener('load', () => {
	const cookie = document.cookie.split('; ').find((row) => row.startsWith('cookieconsent_status='));
	if (cookie) {
		const consent = cookie.split('=')[1];
		if (consent === 'allow') {
			try {
				//? check if newrelic is loaded
				// console.log(newrelic.info.sa);

				//? newrelic release name
				newrelic.addRelease(RELEASE_NAME, RELEASE_ID);
				// console.log(RELEASE_NAME + ' ' + RELEASE_ID);

				// newrelic.noticeError(new Error('This is a new test error'));
				// newrelic.setCustomAttribute('pageName', pageName);
			} catch (error) {
				console.log('new relic not loaded');
			}
		}
	}
});

// export default function PreReleaseOverlay() {
// 	// import.meta.env.MODE === "dedevelopment" ?
// 	const [overlay, setOverlay] = React.useState(false);

// 	React.useEffect(() => {
// 		const host = window.location.hostname;
// 		if (host.includes("pre-release") || host.includes("localhost")) {
// 			setOverlay(true);
// 		}
// 	}, []);

// 	return overlay ? (
// 		<Backdrop
// 			// sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
// 			sx={{
// 				// backgroundColor: "rgba(97, 97, 97, 0.1)",
// 				backgroundColor: "transparent",
// 				zIndex: 999,
// 				pointerEvents: "none",
// 			}}
// 			open={true}
// 		>
// 			<Typography
// 				sx={{
// 					fontFamily: "Open Sans",
// 					opacity: 0.15,
// 					fontSize: 140,
// 					transform: "rotate(-25deg)",
// 				}}
// 			>
// 				PRE-RELEASE
// 			</Typography>
// 		</Backdrop>
// 	) : null;
// }

window.addEventListener('load', () => {
	//* create "pre-release" overlay
	const host = window.location.hostname;
	// console.log(host);
	if (host.includes('pre-release') || host.includes('localhost')) {
		const overlay = document.createElement('div');
		overlay.id = 'pre-release-overlay';
		overlay.innerHTML = 'STAGING';
		overlay.style.fontSize = '1.8rem';
		overlay.style.opacity = '0.4';
		overlay.style.position = 'fixed';
		overlay.style.zIndex = '999';
		overlay.style.pointerEvents = 'none';

		overlay.style.top = '50%';
		overlay.style.right = '-2.5rem';
		overlay.style.transform = 'translateY(-50%) rotate(90deg)';

		document.body.appendChild(overlay);
	}
});

window.addEventListener('load', () => {
	const path = window.location.pathname;
	// console.log(path);
	if (path !== '/') {
		let nav_container = document.getElementById('nav-icon3');
		nav_container.classList.add('yobo_blue');
		nav_container.addEventListener('click', () => {
			$('#nav-icon3').toggleClass('yobo_blue');
		});
		let overlayToggle = document.getElementById('overlayToggle');
		overlayToggle.addEventListener('click', () => {
			$('#nav-icon3').toggleClass('yobo_blue');
		});
	}
	if (document.documentElement.clientWidth < 995) {
		let nav_container = document.getElementById('nav-icon3');
		let overlayToggle = document.getElementById('overlayToggle');
		nav_container.addEventListener('click', () => {
			$('#page_logo').toggleClass('hidden');
		});
		// overlayToggle.addEventListener('click', () => {
		// 	$('#page_logo').toggleClass('hidden');
		// });
	}
});
