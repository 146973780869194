// import './style.css'
// import javascriptLogo from './javascript.svg'
// import { setupCounter } from './counter.js'

// import img img/yobo/Logo/yobo_Logo_weiss.svg

import img_logo from '../img/yobo/Logo/yobo_Logo_weiss.svg';

export default () => {
	document.querySelector('#menu-block').innerHTML = `
    <!-- Sidebar -->
    <nav id="sidebar">
        <ul class="list-unstyled font-weight-bold a-Black">
            <!-- <li>
                        <a href="shop.html">Shop</a>
                    </li>
                    <li>
                        <br>
                    </li> -->
            <!-- <li class="active"> -->

                <li>
                    <a href="/">Home</a>
                </li>
                <li>
                    <a href="philosophie.html">Über uns</a>
                </li>
                <li>
                    <a href="leistungs-pakete.html">Leistungs-Pakete</a>
                </li>
                
                <!-- <li>
                    <a
                        class="clickPlusToggle_3"
                        href="#TOP4Submenu"
                        data-toggle="collapse"
                        aria-expanded="false"
                        >Leistungs-Pakete<span class="plusToggle toggle_3"></span
                    ></a>
                    <ul class="collapse list-unstyled" id="TOP4Submenu">
                        <li>
                            <a href="leistungs-pakete.html">Körper-Training-Paket</a>
                        </li>
                        <li>
                            <a href="leistungs-pakete.html">Entspannungs-Training-Paket</a>
                        </li>
                    
                        <li>
                            <a href="leistungs-pakete.html">Ernährungs-Training-Paket</a>
                        </li>
                       
                        
                    </ul>
                </li> -->

                <li>
                    <a
                        class="clickPlusToggle_4"
                        href="#CoachingSubmenu"
                        data-toggle="collapse"
                        aria-expanded="false"
                        >Analysen<span class="plusToggle toggle_4"></span
                    ></a>
                    <ul class="collapse list-unstyled" id="CoachingSubmenu">
                        <li>
                            <a href="analysen-athletik.html">Analysen „Athletik“</a>
                        </li>	
                        <li>
                            <a href="analysen-gesundheit.html">Analysen „Gesundheit“</a>
                        </li>	
                        <li>
                            <a href="grundlagen-check.html">Grundlagen-Check</a>
                        </li>
                        <li>
                            <a href="labor-analysen.html">Analysen „Labor“</a>
                        </li>
                    <!--  <li>
                            <a href="yobo-app.html">YOBŌ APP</a>
                        </li>			
                        <li>
                            <a href="trainingsplan.html">Trainingsplanerstellung</a>
                        </li>
                        <li>
                            <a href="video-coaching.html">Video Coaching</a>
                        </li>
                      <li>
                            <a href="coaching-programme.html">Olymp Programme</a>
                        </li>
                        -->

                    </ul>
                </li>


                <!-- <li>
                    <a
                        class="clickPlusToggle_5"
                        href="#Extras"
                        data-toggle="collapse"
                        aria-expanded="false"
                        >Extras<span class="plusToggle toggle_5"></span
                    ></a>
                    <ul class="collapse list-unstyled" id="Extras">
        
                        <li>
                            <a href="labor-analysen.html">Labor Analysen</a>
                        </li>
                    </ul>
                </li> -->
                <!-- 
                <li >
                    <a class="" href="/#analyse-finder/analyse-abo" 
                    >Abonnements</a>
                </li>-->
                <!-- <li id="samePage" class="toggleScroll turnOn">
                    <a href="/#analyse-finder/analyse-abo">Abonnements</a>
                </li>-->
            <li>
                <a
                    class="clickPlusToggle_6"
                    href="#TOP4AnalysenSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    >Specials<span class="plusToggle toggle_6"></span
                ></a>
                <ul class="collapse list-unstyled" id="TOP4AnalysenSubmenu">
                    <li>
                        <a href="specials.html?toggleUnternehm">Für Unternehmer</a>
                    </li>
                    <li>
                        <a href="specials.html?toggleFuehrung">Für Führungskräfte</a>
                    </li>
                    <li>
                        <a href="specials.html?toggleLeistung">Für Leistungssportler</a>
                    </li>
                    <li>
                        <a href="specials.html?toggleFreizeit">Für Freizeitsportler</a>
                    </li>
                </ul>
            </li>
            <li>
            <br />
        </li>
            <li>
            <a href="https://www.mysports.com/studio/eW9ibzoxMjEwMDA0MTcw/course">Trainings-Buchung ↗</a>
        </li>

        <li>
        <a href="https://www.mysports.com/studio/eW9ibzoxMjEwMDA0MTcw/appointments">Einzeltermin-Buchung ↗</a>
    </li>

            <!--<li>
                <a href="shop.html">Leistungsangebot</a>
            </li> -->
        
            <li>
                <br />
            </li>

            <div style="font-weight: 400">
            <!-- 
             <li>
                <a href="${BMA_URL}/login" target="_blank"
                    >LOG-IN
                </a>
            </li>
            <li>
                <a href="${BMA_URL}/freebee/login" target="_blank"
                    >Probezeit LOG-IN
                </a>
            </li>-->

            <li>
                <br />
            </li>

            <li id="samePage" class="toggleScroll turnOn">
                <a href="/#kontakt">Kontakt</a>
            </li>
            <li id="samePage" class="toggleScroll">
                <a href="impressum.html">Impressum</a>
            </li>
            <li id="samePage" class="toggleScroll">
                <a href="datenschutz.html">Datenschutz</a>
            </li>
            <li id="samePage" class="toggleScroll">
                <a href="agb.html">AGB</a>
            </li>
            
            </div>
            </ul>
            
            <div style="display: flex; height: 100%; align-items: flex-end; margin-bottom: 40px; margin-left: 40px; margin-top: 20px">
                <a href="/">
                <img src="${img_logo}" style="height:50px" alt="logo" />
                </a>
            </div>
    </nav>
    <!-- SIDEBAR MENU -->`;

	// $(document).ready(function () {
	// 	$("#sidebar").mCustomScrollbar({
	// 		theme: "minimal-dark",
	// 	});

	// 	//enable scroll if sidebar is in-active (contains needs some time)
	// 	// setTimeout(function () {
	// 	// 	var navBar = document.getElementById("sidebar");
	// 	// 	if (!navBar.classList.contains("active")) {
	// 	// 		try {
	// 	// 			$.fn.fullpage.setAllowScrolling(true);
	// 	// 		} catch (error) {
	// 	// 			console.log("fullpage.js is not part of subpages");
	// 	// 		}
	// 	// 		window.onscroll = function () {};
	// 	// 	}
	// 	// }, 200);
	// 	// console.log("document ready in menu block");
	// });

	var toggle = false;
	// New burger
	// $(document).ready(function () {
	$(() => {
		$('#nav-icon3, #overlayToggle, #samePage').click(function () {
			$('#nav-icon3').toggleClass('open');

			// open sidebar
			$('#sidebar').toggleClass('active');
			// fade in the overlay
			$('.overlay').toggleClass('active');
		});
	});
	$('.menu-toggler').on('click', () => {
		$('#nav-icon3').toggleClass('open');
		// open sidebar
		$('#sidebar').toggleClass('active');
		// fade in the overlay
		$('.overlay').toggleClass('active');
	});

	//plusToggle (absolutely not a good solution, leon consents)
	$('.clickPlusToggle_1').on('click', () => {
		$('.toggle_1').toggleClass('clicked');
	});
	$('.clickPlusToggle_2').on('click', () => {
		$('.toggle_2').toggleClass('clicked');
	});
	$('.clickPlusToggle_3').on('click', () => {
		$('.toggle_3').toggleClass('clicked');
	});
	$('.clickPlusToggle_4').on('click', () => {
		$('.toggle_4').toggleClass('clicked');
	});
	$('.clickPlusToggle_5').on('click', () => {
		$('.toggle_5').toggleClass('clicked');
	});
	$('.clickPlusToggle_6').on('click', () => {
		$('.toggle_6').toggleClass('clicked');
	});

	//scroll on/off
	$('.turnOn').on('click', function () {
		// console.log("BURGER ON CLICK");
		try {
			$.fn.fullpage.setAllowScrolling(true);
		} catch (error) {
			console.log('fullpage.js is not part of subpages');
		}

		document.getElementsByTagName('BODY')[0].style.overflow = 'visible';

		//scroll-lock problem on safari
		// window.onscroll = function () {};
		// console.log("turnOn function");
	});
	$('.turnOff').on('click', function () {
		// console.log("BURGER OFF CLICK");
		try {
			$.fn.fullpage.setAllowScrolling(false);
		} catch (error) {
			// console.log("fullpage.js is not part of subpages");
		}

		document.getElementsByTagName('BODY')[0].style.overflow = 'hidden';

		//scroll-lock problem on safari
		// scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		// (scrollLeft = window.pageXOffset || document.documentElement.scrollLeft),
		// 	(window.onscroll = function () {
		// 		window.scrollTo(scrollLeft, scrollTop);
		// 	});
		// console.log("turnOff function");
	});

	//toggle on/off
	var scrollToggleMenu = false;
	$('#nav-icon3, #overlayToggle, .toggleScroll').click(function () {
		if (scrollToggleMenu === false) {
			try {
				$.fn.fullpage.setAllowScrolling(false);
			} catch (error) {
				// console.log("fullpage.js is not part of subpages");
			}

			document.getElementsByTagName('BODY')[0].style.overflow = 'hidden';

			//scroll-lock problem on safari
			// scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			// (scrollLeft = window.pageXOffset || document.documentElement.scrollLeft),
			// 	(window.onscroll = function () {
			// 		window.scrollTo(scrollLeft, scrollTop);
			// 	});

			scrollToggleMenu = true;
			// console.log("toggle snippet ON");
		} else if (scrollToggleMenu === true) {
			try {
				$.fn.fullpage.setAllowScrolling(true);
			} catch (error) {
				// console.log("fullpage.js is not part of subpages");
			}
			document.getElementsByTagName('BODY')[0].style.overflow = 'visible';

			//scroll-lock problem on safari
			// window.onscroll = function () {};

			scrollToggleMenu = false;
			// console.log("toggle snippet OFF");
		}
	});
};
