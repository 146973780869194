// Google Analytics

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return false;
	}
}
export default () => {
	window.addEventListener('load', () => {
		// console.log("GA JS");

		//get cookie data
		const cookie = document.cookie.split('; ').find((row) => row.startsWith('cookieconsent_status='));
		// console.log(cookie);
		if (inIframe()) {
			return;
		} else if (cookie === undefined) {
			console.log('cookieValue is NOT set');
			return;
		} else if (cookie.split('=')[1] === 'allow') {
			console.log('cookieValue is set to -> allow');
			//hide cookieconsent-overlay
			try {
				document.getElementById('cookieconsent-overlay').style.display = 'none';
				// document.getElementsByClassName("cc-revoke")[0].style.display = "none";
				// document.getElementsByClassName("cc-window")[0].style.display = "none";
			} catch (error) {
				console.log('cookieconsent-overlay is probably commented out');
			}

			//create async script in head
			let scriptGA = document.createElement('script');
			scriptGA.src = 'https://www.googletagmanager.com/gtag/js?id=G-R4PXL6S3NC';
			document.head.append(scriptGA);

			//Global site tag (gtag.js)
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());
			gtag('config', 'G-R4PXL6S3NC');

			// Google Tag Manager
			(function (w, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s),
					dl = l != 'dataLayer' ? '&l=' + l : '';
				j.async = true;
				j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
				f.parentNode.insertBefore(j, f);
			})(window, document, 'script', 'dataLayer', 'GTM-M6FP72J');
		} else {
			console.log('cookieValue is set to -> deny');
			//hide cookieconsent-overlay
			try {
				document.getElementById('cookieconsent-overlay').style.display = 'none';
				// document.getElementsByClassName("cc-revoke")[0].style.display = "none";
				// document.getElementsByClassName("cc-window")[0].style.display = "none";
			} catch (error) {
				console.log('cookieconsent-overlay is probably commented out');
			}
		}

		// * manual mode, always run google-analytics, can be deleted later
		if (false) {
			//create async script in head
			let scriptGA = document.createElement('script');
			scriptGA.src = 'https://www.googletagmanager.com/gtag/js?id=G-R4PXL6S3NC';
			document.head.append(scriptGA);

			//Global site tag (gtag.js)
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());
			gtag('config', 'G-R4PXL6S3NC');

			// Google Tag Manager
			(function (w, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s),
					dl = l != 'dataLayer' ? '&l=' + l : '';
				j.async = true;
				j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
				f.parentNode.insertBefore(j, f);
			})(window, document, 'script', 'dataLayer', 'GTM-M6FP72J');
		}
	});
};
